.textinput label {
  pointer-events: none;
}

.textinput label {
  transform: translateY(-50%);
  left: 7px;
}

.textinput input:focus-within ~ label,
.textinput input:not(:placeholder-shown) ~ label {
  @apply top-0;
  /* @apply bg-slate-100; */
  transform: scale(70%) translateY(10%);
  z-index: 11;
}

.textinput input:focus-within ~ label {
  @apply text-indigo-500;
}

.text-link {
  @apply font-medium text-indigo-600 hover:text-indigo-500;
}

.text-error {
  @apply text-red-600 font-light text-sm pt-2;
}
